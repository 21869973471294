'use strict';

import $ from 'jquery';

export default {
    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('click', '[data-toggle-sidebar]', this.toggleSidebar);
        $('body').on('close-sidebars', this.closeSidebars.bind(this));
    },

    toggleSidebar(e) {
        const $this = $(this),
            sidebar = $this.data('toggle-sidebar'),
            $triggers = $(`[data-toggle-sidebar="${sidebar}"]`),
            $sidebar = $(`[data-sidebar="${sidebar}"]`);

        e.preventDefault();

        if ($sidebar && $sidebar.length > 0) {
            $sidebar.toggleClass('toggled');
            $triggers.toggleClass('toggled');
        }
    },

    closeSidebars(e) {
        $('[data-toggle-sidebar]').removeClass('toggled');
        $('[data-sidebar]').removeClass('toggled');
    }
};
