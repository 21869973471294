import $ from 'jquery';
import Cookies from 'js-cookie';

const Store = function() {
    this.syncValues();
    this.bindEvents();
};

Store.prototype.bindEvents = function() {
    $('body').on('keyup change', '[data-store-sync]', this.onChangeSyncField.bind(this));
};

Store.prototype.set = function(key, value) {
    if (!key) {
        return;
    }

    Cookies.set(key, JSON.stringify(value), { expires: 365 });
};

Store.prototype.get = function(key) {
    return Cookies.get(key) ? JSON.parse(Cookies.get(key)) : undefined;
};

Store.prototype.onChangeSyncField = function(e) {
    const $field = $(e.currentTarget),
        key = $field.data('store-sync');

    this.set(key, $field.val());
};

Store.prototype.syncValues = function() {
    $('[data-store-sync]').each((index, field) => {
        const $field = $(field),
            key = $field.data('store-sync'),
            value = this.get(key);

        if ($field.length === 0 || value === undefined) {
            return false;
        }

        if ($field.is('select') && $field.find(`option[value="${parseInt(value, 10)}"]`).val() !== undefined) {
            $field.val(parseInt(value, 10)).trigger('change');
        } else {
            $field.val(value);
        }
    });
};

// Exported as a singleton.
export default new Store();
