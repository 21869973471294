// <span data-rotate-words="afstudeerstage, stage, bijbaan">afstudeerstage</span>

const Rotator = function($rotator) {
    if (!$rotator || $rotator.length === 0) {
        return false;
    }

    this.currentIndex = 0;
    this.$rotator = $rotator;

    // Doesn't support strings with spaces atm.
    this.words = $rotator.data('rotate-words').trim().replace(/\s/g, '').split(',');

    this.interval = setInterval(function() {
        this.nextWord();
    }.bind(this), 1800);
};

Rotator.prototype.nextWord = function() {
    const nextIndex = this.currentIndex < this.words.length - 1 ? this.currentIndex + 1 : 0;

    this.$rotator.text(this.words[nextIndex]);

    this.currentIndex = nextIndex;
};

export default {
    init() {
        $('[data-rotate-words]').each((index, rotator) => {
            const instance = new Rotator($(rotator));
        });
    }
};
