'use strict';

import $ from 'jquery';
import Store from './storage';

export default {
    init() {
        this.prefillFields();
    },

    prefillFields() {
        this.prefill('education', Store.get('SB_STUDENTS_FIELD_EDUCATION'));
        this.prefill('job_type', Store.get('SB_STUDENTS_FIELD_JOBTYPE'));
        this.prefill('email', Store.get('SB_STUDENTS_FIELD_EMAIL'));

        // NOTE: Region as a free input field cannot easily be matched to select options without an ID.
        // this.prefill('preferred_region', Store.get('SB_STUDENTS_FIELD_REGION'));
    },

    prefill(fieldName, value) {
        const $field = $(`[data-prefill="${fieldName}"]`);
        if ($field.length === 0 || value === undefined) {
            return false;
        }

        if ($field.is('select') && $field.find(`option[value="${value}"]`).val() !== undefined) {
            $field.val(value).trigger('change');
        } else {
            $field.val(value);
        }
    }
};
