'use strict';

/* globals dataLayer */
export default {
    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('click', '[data-candidate-toggle-like]', this.onClickToggleLikeCandidate.bind(this));
    },

    onClickToggleLikeCandidate(e) {
        const $trigger = $(e.currentTarget),
            ID = $trigger.attr('data-candidate-toggle-like');

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'bedrijven',
            eventAction: 'interesse',
            eventLabel: ID
        });
    }
};
