'use strict';

export default {
    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('change', '[data-ajax-upload]', this.onSelectFile.bind(this));
    },

    onSelectFile(e) {
        e.preventDefault();
        const $fileUpload = $(e.currentTarget),
            $form = $fileUpload.parents('form');

        if (!$form.is(':disabled')) {
            this.submitForm($form, $fileUpload);
        }
    },

    submitForm($form, $fileUpload) {
        if (typeof window.FormData === 'undefined') {
            alert('Helaas werkt deze upload niet in verouderde browsers. Je kunt je CV wel uploaden op je profielpagina.');
            window.location = '/profiel';
        }

        const data = new FormData();
        // collect data
        $.each($form.serializeArray(), function(key, row) {
            data.append(row.name, row.value);
        });
        // add files
        $form.find('input[type="file"]').each(function() {
            if ($(this)[0].files.length) {
                data.append($(this).attr('name'), $(this)[0].files[0]);
            }
        });

        // Post form to the original action, which is assumed to be an ajax endpoint
        const jqxhr = $.post({
            url: $form.attr('action'),
            cache: false,
            contentType: false,
            processData: false,
            data
        });
        $fileUpload.parent().attr('data-button-loading', 'loading');

        jqxhr.done(this.onLikeSuccess.bind($fileUpload));
        jqxhr.fail(this.onLikeFail.bind($fileUpload));
    },

    onLikeSuccess(data) {
        this.parent().attr('data-button-loading', false);
        this.parent().attr('data-button-success', true);

        console.log(data);
    },

    onLikeFail(data) {
        this.parent().attr('data-button-loading', false);
        this.parent().attr('data-button-success', true);

        console.log(data);

        console.error('Something went wrong uploading the file');
    }
};
