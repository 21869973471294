'use strict';

import $ from 'jquery';

export default {
    $inputWrapper: null,
    $targetWrapper: null,

    postalValue: '',
    houseNumberValue: '',
    houseNumberAdditionValue: '',

    ajaxBaseURI: window.location.protocol + '//' + window.location.host,

    isValid: null,

    init() {
        // Set base URI for ajax
        if (this.ajaxBaseURI === 'localhost:3000') {
            this.ajaxBaseURI = 'https://studentenbureau.test';
        }

        this.setWrappers();
        this.lookup();

        if (this.$inputWrapper.length && this.$targetWrapper.length) {
            this.postalValue = this.$inputWrapper.find('[data-postal-autofill-field="postal_code"]').val();
            this.houseNumberValue = this.$inputWrapper.find('[data-postal-autofill-field="house_number"]').val();
            this.bindEvents();
        }
    },

    setWrappers() {
        this.$inputWrapper = $('[data-postal-autofill-target]');
        if (this.$inputWrapper.length) {
            this.$targetWrapper = $(this.$inputWrapper.data('postal-autofill-target'));
        }
    },

    bindEvents() {
        this.$inputWrapper.find('[data-postal-autofill-field]').on('keyup', this.lookup.bind(this));
    },

    lookup() {
        this.postalValue = this.$inputWrapper.find('[data-postal-autofill-field="postal_code"]').val();
        this.houseNumberValue = this.$inputWrapper.find('[data-postal-autofill-field="house_number"]').val();

        if (this.hasSufficientData()) {
            const data = {
                postal_code: this.postalValue,
                house_number: this.houseNumberValue
            };

            this.$targetWrapper.find('[data-postal-autofill-field="street"]').val('');
            this.$targetWrapper.find('[data-postal-autofill-field="city"]').val('');
            this.$targetWrapper.find('[data-postal-autofill-field="lat"]').val('');
            this.$targetWrapper.find('[data-postal-autofill-field="lon"]').val('');
            this.$targetWrapper.attr('data-state', 'loading');
            $.get(this.ajaxBaseURI + '/api/geo/postal-to-address', data)
                .done(this.handleGeoResult.bind(this))
                .fail(this.handleGeoError.bind(this));
        } else if (this.$targetWrapper !== null) {
            this.$targetWrapper.attr('data-state', 'empty');
        }
    },

    hasSufficientData() {
        // TODO: integrate with form-validation.js
        if (typeof this.postalValue === 'undefined' || typeof this.houseNumberValue === 'undefined') {
            return false;
        }
        return !!(this.postalValue.length >= 6 && this.houseNumberValue.length >= 1);
    },

    handleGeoResult(data, xhr) {
        const validationField = $('[data-parsley-zipcode]').parsley();

        if (data.latitude === null || data.longitude === null) {
            this.setErrorState();
        } else {
            this.$targetWrapper.find('[data-postal-autofill-field="street"]').val(data.street);
            this.$targetWrapper.find('[data-postal-autofill-field="city"]').val(data.town);
            this.$targetWrapper.find('[data-postal-autofill-field="lat"]').val(data.latitude);
            this.$targetWrapper.find('[data-postal-autofill-field="lon"]').val(data.longitude);
            if (typeof validationField.removeError !== 'undefined') {
                validationField.removeError('postalLookupFailure');
            }
            this.$targetWrapper.attr('data-state', 'visible');
        }
    },

    handleGeoError(xhr) {
        if (xhr.status === 429) {
            this.enableManualInput();
        } else {
            this.setErrorState();
        }
    },

    setErrorState() {
        const validationField = $('[data-parsley-zipcode]').parsley();
        if (typeof validationField.removeError !== 'undefined') {
            validationField.removeError('postalLookupFailure');
        }
        this.$targetWrapper.find('[data-postal-autofill-field="street"]').val('');
        this.$targetWrapper.find('[data-postal-autofill-field="city"]').val('');
        this.$targetWrapper.find('[data-postal-autofill-field="lat"]').val('');
        this.$targetWrapper.find('[data-postal-autofill-field="lon"]').val('');
        if (typeof validationField.addError !== 'undefined') {
            validationField.addError('postalLookupFailure', { message: 'Kon geen adres vinden met deze postcode en dit huisnummer' });
        }
        this.$targetWrapper.attr('data-state', 'empty');
    },

    enableManualInput() {
        this.$targetWrapper.attr('data-state', 'visible');
        this.$targetWrapper.find('input').removeClass('input--inline');
        const validationField = $('[data-parsley-zipcode]').parsley();
        validationField.removeError('postalLookupFailure');
        if (typeof validationField.addError !== 'undefined') {
            validationField.addError('postalLookupFailure', { message: 'Het lukte helaas niet om je adres op te halen, vul hem hieronder zelf even in' });
        }
    }
};
