import $ from 'jquery';
import Store from '../components/storage';
import Prefiller from '../components/prefiller';

/* globals dataLayer */
const ModalWatcher = {
    init() {
        this.bindEvents();
        this.openOnLoad();
    },

    bindEvents() {
        $('body').on('click', '[data-trigger-modal]', this.onClickTriggerModal.bind(this));
        $('body').on('trigger-modal', this.onTriggerModal.bind(this));
        $('body').on('modal-scrollto', this.onModalScrollTo.bind(this));

        $('body').on('hidden.bs.modal', '[data-modal]', this.onHiddenModal.bind(this));
        $('body').on('shown.bs.modal', '[data-modal]', this.onShownModal.bind(this));
    },

    openOnLoad() {
        if (window.location.hash) {
            $('body').trigger('trigger-modal', [window.location.hash, 'url-trigger']);
        } else if ($('body').attr('data-num-vacancies') > 2 && !Store.get('register-modal-toggled')) { // Automagically open a modal when viewing a second vacancy.
            $('[data-modal="inschrijven"] h3[data-modal-title]').html('Je zoekt een gave vacature? Geef aan wat je zoekt, dan zoeken wij met je mee en ontvang je nieuwe vacatures meteen in je inbox.');
            Store.set('register-modal-toggled', true);
            $('body').trigger('trigger-modal', ['inschrijven', 'auto-trigger']);
        }
    },

    onClickTriggerModal(e) {
        const $trigger = $(e.currentTarget),
            modalName = $trigger.data('trigger-modal') ? $trigger.data('trigger-modal') : $trigger.attr('href');

        $('body').trigger('trigger-modal', [modalName, 'trigger', $trigger]);
    },

    onTriggerModal(e, modalName, action = 'trigger', $trigger = null) {
        const strippedName = modalName.replace(/#/g, ''),
            $modal = $(`[data-modal="${strippedName}"]`),
            $title = $modal.find('[data-modal-title]');

        if ($trigger && $title.length && $trigger[0].hasAttribute('data-modal-title')) {
            if (!$title[0].hasAttribute('data-original')) {
                $title.attr('data-original', $title.html());
            }
            $title.html($trigger.attr('data-modal-title'));
        }

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'modal',
            eventAction: action,
            eventLabel: strippedName
        });

        if ($modal && $modal.length > 0) {
            $('[data-modal]').modal('hide');

            this.$currentModal = $modal;
            $modal.modal('show');
        }
    },

    onShownModal() {
        // Reinitializations
        $('body').trigger('reinit-multiline-form');
        Prefiller.prefillFields();
    },

    onHiddenModal(e) {
        const $modal = $(e.currentTarget),
            name = $modal.data('modal'),
            strippedName = name.replace(/#/g, ''),
            hash = '#' + name,
            $title = $modal.find('[data-modal-title]');

        if ($title.length && $title[0].hasAttribute('data-original')) {
            $title.html($title.attr('data-original'));
        }

        if (hash === window.location.hash) {
            window.location.href = window.location.href.replace(/#.*$/, '#');
        }

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'modal',
            eventAction: 'hidden',
            eventLabel: strippedName
        });
    },

    onModalScrollTo(e, scrollPosition) {
        const $modal = $('.modal.in');

        $modal.animate({
            scrollTop: scrollPosition
        }, 0);
    }
};

export default ModalWatcher;
