'use strict';

import $ from 'jquery';
import Store from './storage';
import Prefiller from './prefiller';

const DynamicSentence = function(type) {
    const $sentence = $(`[data-sentence="${type}"]`);
    this.sentenceType = type;

    if (!$sentence || $sentence.length === 0) {
        return false;
    }
    this.$sentence = $sentence;

    this.prefillSentence = this.$sentence.attr('data-sentence-prefill');

    this.setupParts();
    this.bindEvents();
    this.isReady = true;
};

DynamicSentence.prototype.bindEvents = function() {
    this.$sentence.on('keyup change', '[data-sentence-part]', this.onChangeSentencePart.bind(this));

    this.$sentence.find('[data-sentence-part]').trigger('keyup');
};

DynamicSentence.prototype.setupParts = function() {
    if (this.sentenceType === 'student') {
        this.initializeStudentFields();
    } else if (this.sentenceType === 'company') {
        this.initializeCompanyFields();
    }
};

DynamicSentence.prototype.initializeStudentFields = function() {
    const sentenceParts = {
        $study: $('[data-sentence-part="students-study"]'),
        $type: $('[data-sentence-part="students-type"]'),
        $location: $('[data-sentence-part="students-location"]')
    };

    // Study
    if (sentenceParts.$study.length > 0) {
        if (this.prefillSentence) {
            sentenceParts.$study.val(Store.get('SB_STUDENTS_FIELD_EDUCATION'));
        }

        sentenceParts.$study.select2({
            placeholder: 'typ jouw studie',
            minimumResultsForSearch: 10,
            dropdownParent: $('.js--select2-dropdown-container'),
            language: {
                noResults() {
                    return 'Geen studies gevonden';
                }
            }
        });
    }

    // Type
    if (sentenceParts.$type.length > 0) {
        if (this.prefillSentence) {
            sentenceParts.$type.val(Store.get('SB_STUDENTS_FIELD_JOBTYPE'));
        }

        sentenceParts.$type.select2({
            placeholder: 'soort vacature',
            minimumResultsForSearch: -1,
            dropdownParent: $('.js--select2-dropdown-container'),
            language: {
                noResults() {
                    return 'Geen soorten gevonden';
                }
            }
        });
    }

    // Location
    if (sentenceParts.$location.length > 0 && this.prefillSentence) {
        sentenceParts.$location.val(Store.get('SB_STUDENTS_FIELD_REGION'));
    }
};

DynamicSentence.prototype.initializeCompanyFields = function() {
    const sentenceParts = {
        $expertise: $('[data-sentence-part="companies-expertise"]'),
        $type: $('[data-sentence-part="companies-type"]'),
        $location: $('[data-sentence-part="companies-location"]')
    };

    // Expertise
    if (sentenceParts.$expertise.length > 0) {
        if (this.prefillSentence) {
            sentenceParts.$expertise.val(Store.get('SB_COMPANIES_FIELD_EXPERTISE'));
        }

        sentenceParts.$expertise.select2({
            placeholder: 'typ een vakgebied',
            minimumResultsForSearch: 10,
            dropdownParent: $('.js--select2-dropdown-container'),
            language: {
                noResults() {
                    return 'Geen expertises gevonden';
                }
            }
        });
    }

    // Type
    if (sentenceParts.$type.length > 0) {
        if (this.prefillSentence) {
            sentenceParts.$type.val(Store.get('SB_COMPANIES_FIELD_JOBTYPE'));
        }

        sentenceParts.$type.select2({
            placeholder: 'soort kandidaat',
            minimumResultsForSearch: -1,
            dropdownParent: $('.js--select2-dropdown-container'),
            language: {
                noResults() {
                    return 'Geen dienstverbanden gevonden';
                }
            }
        });
    }

    // Location
    if (sentenceParts.$location.length > 0) {
        if (this.prefillSentence) {
            sentenceParts.$location.val(Store.get('SB_COMPANIES_FIELD_REGION'));
        }

        sentenceParts.$location.select2({
            placeholder: 'regio',
            minimumResultsForSearch: 10,
            dropdownParent: $('.js--select2-dropdown-container'),
            language: {
                noResults() {
                    return 'Geen regio\'s gevonden';
                }
            }
        });
    }
};

DynamicSentence.prototype.onChangeSentencePart = function(e) {
    const $part = $(e.currentTarget);

    if (!$part || $part.length === 0) {
        return false;
    }

    const val = $part.val() ? $part.val() : '';

    if (val.length === 0 && !$part.hasClass('is-empty')) {
        $part.addClass('is-empty');
    } else if ($part.hasClass('is-empty')) {
        $part.removeClass('is-empty');
    }

    if (this.isReady) {
        this.storeSentenceValues();
    }
};

// TODO: Connect this to the store sync functionality?
DynamicSentence.prototype.storeSentenceValues = function() {
    if (this.sentenceType === 'student') {
        Store.set('SB_STUDENTS_FIELD_EDUCATION', $('[data-sentence-part="students-study"]').val());
        Store.set('SB_STUDENTS_FIELD_JOBTYPE', $('[data-sentence-part="students-type"]').val());
        Store.set('SB_STUDENTS_FIELD_REGION', $('[data-sentence-part="students-location"]').val());
    } else if (this.sentenceType === 'company') {
        Store.set('SB_COMPANIES_FIELD_EXPERTISE', $('[data-sentence-part="companies-expertise"]').val());
        Store.set('SB_COMPANIES_FIELD_JOBTYPE', $('[data-sentence-part="companies-type"]').val());
        Store.set('SB_COMPANIES_FIELD_REGION', $('[data-sentence-part="companies-location"]').val());
    }
};

DynamicSentence.prototype.storeValue = function(key, value) {
    if (this.prefillSentence) {
        Store.set(key, value);
        Prefiller.prefillFields();
    }
};

export default DynamicSentence;
