'use strict';

/* globals dataLayer */
export default {
    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('click', '[data-vacancy-toggle-like]', this.onClickToggleLikeVacancy.bind(this));
    },

    onClickToggleLikeVacancy(e) {
        const $trigger = $(e.currentTarget),
            ID = $trigger.attr('data-vacancy-toggle-like');

        if (!$trigger.is(':disabled')) {
            this.likeVacancy(ID, $trigger);

            dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'studenten',
                eventAction: 'interesse',
                eventLabel: ID
            });
        }
    },

    likeVacancy(ID, $trigger) {
        const jqxhr = $.post(`/api/like/${ID}`, {
            id: ID
        });

        // It would be better if the currentID was passed by data in the response directly.
        this.currentID = ID;
        $trigger.attr('data-loading', 'true');

        jqxhr.done((data) => {
            this.onLikeSuccess(data, $trigger);
        });
        jqxhr.fail((data) => {
            this.onLikeFail(data, $trigger);
        });
    },

    onLikeSuccess(data, $trigger) {
        if ($trigger.data('vacancy-toggle-auth')) {
            this.setStatus('liked');
        }
        this.triggerModal();

        $trigger.attr('data-loading', 'false');
    },

    triggerModal() {
        // Trigger like modal
        $('body').trigger('trigger-modal', ['interesse']);
    },

    onLikeFail(data, $trigger) {
        console.error(`Something went wrong liking vacancy with id: ${this.currentID}`);
        $trigger.attr('data-loading', 'false');
        this.setStatus('error');
    },

    setStatus(newStatus = '') {
        const $triggers = $(`[data-vacancy-toggle-like="${this.currentID}"]`);

        $triggers.attr('data-liked-status', newStatus);

        if (newStatus === 'liked') {
            $triggers.attr('disabled', true);
        }
    }
};
