'use strict';

/*
|--------------------------------------------------------------------------
| Social
|--------------------------------------------------------------------------
|
| Watch clicks on social buttons.
|
*/

import $ from 'jquery';

/* globals dataLayer */
export default {
    defaultTitle: 'Lees dit bericht',

    platforms: ['twitter', 'linkedin', 'facebook', 'whatsapp', 'email'],

    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('click', '[data-share]', this.onClickShareButton.bind(this));
    },

    /**
     * Event which triggers when the user clicks on any share button.
     *
     * A share button is marked by the data-share attribute.
     * Possible values should be in the this.platforms array, and have a matching method which
     * handles the share functionality.
     *
     * Example:
     *
     * <a href="https://www.digitalnatives.nl/awesome" data-share="facebook">Share button</a>
     *
     *
     * Extra properties to be added:
     * data-share-title - Add a custom title to add in the share modal.
     *
     * @param  {object} e DOM Event
     */
    onClickShareButton(e) {
        const $button = $(e.currentTarget),
            platform = $button.data('share');

        // Make sure a button was provided & it has a valid platform set.
        if (!$button || !$button.length > 0 || !platform || $.inArray(platform.toLowerCase(), this.platforms) === -1) {
            return false;
        }

        if (platform !== 'email') {
            e.preventDefault();
        }

        const title = $button.data('share-title') ? $button.data('share-title') : this.defaultTitle,
            url = $button.attr('href') ? $button.attr('href') : window.location.origin + window.location.pathname;

        this[platform](url, title);
    },

    /* Listen to click events on whatsApp buttons. On click, share the message on whatsapp. */
    whatsapp(url, title) {
        window.location.href = ('https://wa.me/?text=' + encodeURIComponent(title) + url);

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'whatsapp',
            eventAction: 'click share button'
        });
    },

    /* Listen to click events on facebook buttons. On click, open the facebook share dialog for the current page. */
    facebook(url, title) {
        window.open('http://www.facebook.com/sharer/sharer.php?u=' + url + '&t=' + title, 'facebookwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'facebook',
            eventAction: 'click share button'
        });
    },

    /* Listen to click events on Twitter buttons. */
    twitter(url, title) {
        // We trigger a new window with the Twitter dialog, in the middle of the page
        window.open('http://twitter.com/share?url=' + url + '&text=' + encodeURIComponent(title) + '&', 'twitterwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'twitter',
            eventAction: 'click share button'
        });
    },

    /* Listen to click events on LinkedIn buttons.  */
    linkedin(url, title) {
        // We trigger a new window with the Twitter dialog, in the middle of the page
        window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + encodeURIComponent(title), 'linkedinwindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 225) + ', left=' + $(window).width() / 2 + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'linkedin',
            eventAction: 'click share button'
        });
    },

    /* Listen to click events on email buttons. */
    email() {
        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'email',
            eventAction: 'click share button'
        });
    }
};
