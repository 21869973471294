export default {
    init() {
        this.bindEvents();
    },
    bindEvents() {
        const $body = $('body');
        $body.on('click', '[data-fb-event]', function() {
            const event = $(this).data('fb-event'),
                eventType = $(this).data('fb-event-type');

            // fbq is loaded in by GTM (Facebook Pixel)
            if (typeof fbq === 'function') {
                fbq(event, eventType); // eslint-disable-line
            }
        });
    }
};
