import $ from 'jquery';

const testimonialCarousel = {

    init() {
        this.initializeSlider();
    },

    initializeSlider() {
        $('[data-initialize-slick="testimonial-slider-container"]').slick({
            dots: false,
            arrows: false,
            infinite: true,
            asNavFor: '[data-initialize-slick="testimonial-slider-nav"]',
            autoplay: true
        });

        $('[data-initialize-slick="testimonial-slider-nav"]').slick({
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '[data-initialize-slick="testimonial-slider-container"]',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            cssEase: 'ease-in'
        });
    }
};

export default testimonialCarousel;
