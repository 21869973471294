'use strict';

/**
 * Analytics module.
 * Will send events to Google Tag Manager whenever a click, submit or change happens on
 * a event tracking element.
 *
 * Enable event tracking by adding the following attributes:
 * - data-ga-category=""   --> The Event Category
 * - data-ga-action=""     --> The Event Action
 * - data-ga-label=""      --> The Event Label (Optional)
 * - data-ga-value=""      --> The Event Value (Optional)
 *
 * It's also possible to trigger events within other JavaScript modules. Like this:
 *
 * // NOTE: This is just an example from the Vrij Nederland website.
 * var Analytics = require('../services/Analytics');
 *
 * Analytics.pushEvent({
 *     category: 'load-article',
 *     action: 'scroll',
 *     label: article.url,
 *     value: this.fetchedCount,
 *     nonInteraction: true
 * });
 *
 * Or use it to send a virtual pageView event (to be picked up by a custom dimension in Tag Manager).
 * NOTE: This should be properly set up within tag manager before it works.
 *
 * Analytics.sendVirtualPageview({
 *     url: 'https://www.digitalnatives.nl/contact',
 *     title: 'Digital Natives - Contact'
 * });
 *
 * #https://developers.google.com/analytics/devguides/collection/analyticsjs/events?hl=nl
 */

import { on } from 'delegated-events';

export default {

    init() {
        this.bindEvents();
    },

    bindEvents() {
        this.enableDataAttributes();
    },

    pushEvent(eventData) {
        if (!window.dataLayer || !eventData) {
            return false;
        }

        const event = {
            event: 'TrackEvent',
            eventCategory: eventData.category,
            eventAction: eventData.action,
            eventLabel: eventData.label,
            eventValue: eventData.value,
            eventNonInteraction: !!eventData.nonInteraction
        };

        window.dataLayer.push(event);
    },

    sendVirtualPageview(eventData) {
        if (!window.dataLayer || !eventData) {
            return false;
        }

        if (eventData.url && eventData.title) {
            const event = {
                event: 'VirtualPageView',
                virtualPageUrl: eventData.url,
                virtualPageTitle: eventData.title
            };

            window.dataLayer.push(event);
        }
    },

    /**
     * Google analytics global tracking via data-ga-category
     */
    enableDataAttributes() {
        on('click', '[data-ga-category]', (e) => {
            this.registerElementAction(e.target);
        });

        on('submit', '[data-ga-category]', (e) => {
            this.registerElementAction(e.target);
        });

        on('change', '[data-ga-category]', (e) => {
            this.registerElementAction(e.target);
        });
    },

    registerElementAction(el, type = '') {
        if (!el) {
            return false;
        }

        const category = el.getAttribute('data-ga-category'),
            action = el.getAttribute('data-ga-action'),
            label = el.getAttribute('data-ga-label') || null,
            value = el.getAttribute('data-ga-value') || null,
            nonInteraction = el.getAttribute('data-ga-noninteraction') || false;

        this.pushEvent({
            category,
            action: `${action} (${type})`,
            label,
            value,
            nonInteraction
        });
    }
};
