import $ from 'jquery';
import { debounce } from 'throttle-debounce';

const StickyBar = {
    init() {
        this.$stickybar = $('.sticky-bar');
        this.$stickybarFiller = $('.sticky-bar').parent();

        if (this.$stickybarFiller.length === 0 || this.$stickybar.length === 0) {
            return;
        }

        this.setStickybarFillerOffsetTop();
        this.setStickybarHeight();
        this.checkOffset();
        this.bindEvents();
    },

    bindEvents() {
        $(window).on('scroll', this.checkOffset.bind(this));
        $(window).on('resize', debounce(200, this.onResize.bind(this)));
    },

    onResize() {
        this.setStickybarFillerOffsetTop();
        this.setStickybarHeight();
        this.checkOffset();
    },

    checkOffset() {
        // Als de onderkant boven de container is:
        // Maak sticky
        if (this.getScrollBottomOffset() < this.getStickybarFillerOffsetTop() + this.getStickybarHeight()) {
            this.$stickybar.attr('stickybar-sticky', 'true');
        }

        // Als onderkant van de page voorbij de container komt:
        // Maak static-ish
        if (this.getScrollBottomOffset() >= this.getStickybarFillerOffsetTop() + this.getStickybarHeight()) {
            this.$stickybar.attr('stickybar-sticky', 'false');
        }
    },

    getScrollBottomOffset() {
        return $(window).scrollTop() + window.innerHeight;
    },

    setStickybarFillerOffsetTop() {
        this.stickybarFillerOffsetTop = this.$stickybarFiller.offset().top;
    },

    getStickybarFillerOffsetTop() {
        return this.stickybarFillerOffsetTop;
    },

    setStickybarHeight() {
        this.stickybarHeight = this.$stickybar.outerHeight(true);
    },

    getStickybarHeight() {
        return this.stickybarHeight;
    }
};

export default StickyBar;
