export default {
    filterDelay: 800,

    init() {
        this.$results = $('[data-smooth-results]');
        this.$filters = $(`[data-filter-smooth-results="${this.$results.attr('data-smooth-results')}"]`);
        this.$title = $(`[data-smooth-title="${this.$results.attr('data-smooth-results')}"]`);
        this.$filtersForm = this.$filters.find('form');

        if (!window.history || !this.$results.length > 0) {
            return false;
        }

        this.bindEvents();
    },

    bindEvents() {
        // Listen for pagination clicks
        this.$results.on('click', '.pagination a', this.onClickPaginationLink.bind(this));

        // Listen for filter changes
        this.$filtersForm.on('change', this.onChangeFilters.bind(this));

        // Submit
        this.$filtersForm.on('submit', this.onSubmitForm.bind(this));

        // Results change
        this.$results.on('new-results', this.onNewResults.bind(this));
    },

    onClickPaginationLink(e) {
        e.preventDefault();
        this.showLoader();
        this.updateResults($(e.currentTarget).attr('href'));
    },

    onChangeFilters(e) {
        if ($(window).width() < 768) {
            return false;
        }

        if ($(e.currentTarget).parsley().validate()) {
            this.showLoader();

            if ($(this.$filtersForm).attr('data-legacy-filter') !== '1') {
                this.getPostUrl();
            } else {
                this.updateResults();
            }
        }
    },

    onSubmitForm(e) {
        e.preventDefault();

        this.showLoader();
        if ($(this.$filtersForm).attr('data-legacy-filter') !== '1') {
            this.getPostUrl();
        } else {
            this.updateResults();
        }
    },

    onNewResults(e, { results, title }) {
        this.$results.html(results);
        if (this.$title.length) {
            this.$title.html(title);
        }
        this.hideLoader();
        $('body').trigger('close-sidebars');
    },

    updateResults(url, hasDelay) {
        const parsedUrl = url || window.location.origin + window.location.pathname + '?' + this.$filtersForm.serialize();

        if (typeof this.filterTimeoutID === 'number') {
            window.clearTimeout(this.filterTimeoutID);
            this.filterTimeoutID = undefined;
        }

        if (hasDelay) {
            this.filterTimeoutID = setTimeout(function() {
                this.setUrl(parsedUrl);
            }.bind(this), this.filterDelay);
        } else {
            this.setUrl(parsedUrl);
        }
    },

    getPostUrl() {
        if (this.xhr) {
            this.xhr.abort();
        }

        // Fetching results consists of 2 steps, this is because
        // 3 of the filters (location, vacancy_type_group and educationGroup) we're part of the URL structure"
        // And are now sent as query parameters instead.

        const that = this;
        // To avoid breaking SEO, we have an intermediate step of send the query parameters to the backend
        // And the backend will translate it to the correct URL so the frontend can query the results
        // Step 1: Fetch the correct url
        $.ajax({
            type: 'POST',
            url: window.location.origin + window.location.pathname,
            data: this.$filtersForm.serialize(),
            success(newUrl) {
                // Dynamically change the url to the one we are fetching results from
                that.setUrl(newUrl);
            }
        });
    },

    fetchResults(endpoint) {
        if (this.xhr) {
            this.xhr.abort();
        }
        this.xhr = $.ajax({
            url: endpoint,
            success: this.onFetchedResults.bind(this)
        });
    },

    onFetchedResults(data) {
        if (!data) {
            return false;
        }

        this.xhr = null;

        this.$results.trigger('new-results', [
            {
                results: $(data).find(`[data-smooth-results="${this.$results.attr('data-smooth-results')}"]`).html(),
                title: $(data).find(`[data-smooth-title="${this.$results.attr('data-smooth-results')}"]`).html()
            }
        ]);
    },

    setUrl(url) {
        history.pushState({}, '', url);
        this.fetchResults(url);
    },

    showLoader() {
        this.scrollToTop();
        if (!this.$results.hasClass('loading')) {
            this.$results.addClass('loading');
        }
    },

    hideLoader() {
        this.$results.removeClass('loading');
    },

    scrollToTop() {
        $('html').animate({ scrollTop: this.$results.offset().top - 50 }, 350);
    }
};
