/*! ======================================================================

 Designed and developed by Digital Natives
 http://www.digitalnatives.nl
 ====================================================================== */
'use strict';

import $ from 'jquery';
import slick from 'slick-carousel';
import LazyLoad from 'vanilla-lazyload';
import picker from '../../../node_modules/pickadate/lib/compressed/picker.js';
import datepicker from '../../../node_modules/pickadate/lib/compressed/picker.date.js';

// Components
import Initializer from './components/initializer';
import DynamicSentence from './components/dynamic-sentence';
import SmoothResults from './components/smooth-results';
import TestimonialCarousel from './components/testimonial-carousel';
import TextRotator from './components/text-rotator';
import StickyBar from './components/stickybar';
import Prefiller from './components/prefiller';
import Forms from './components/forms';
import SubNavigation from './components/sub-navigation';
import CookieBar from './components/cookie-bar';

// Watchers
import Analytics from './watchers/analytics';
import ToggleSidebar from './watchers/toggle-sidebar';
import ModalWatcher from './watchers/modals';
import MultistepFormWatcher from './watchers/multistep-form';
import ValidationWatcher from './watchers/form-validation';
import PostalCodeLookupWatcher from './watchers/postalcode-lookup';
import MatchHeightWatcher from './watchers/match-height';
import SocialWatcher from './watchers/social';
import LikeVacancyWatcher from './watchers/like-vacancy';
import LikeCandidateWatcher from './watchers/like-candidate';
import FormAjaxSubmitWatcher from './watchers/form-ajax-submit';
import AjaxUpload from './watchers/ajax-upload';
import FBTriggers from './watchers/fb-triggers';

/* globals dataLayer */

/*
======================================================================
 Global dependencies
======================================================================
*/
// Make dependencies global so modules can use them without having to
// call require(x) all over the place (although that would be nicer)
window.jQuery = window.$ = $;

/*
======================================================================
 Vendor includes
======================================================================
*/

require('./vendor/select2.min.js');
require('./vendor/jquery.autosize.input.js');

// Bootstrap
require('../../../node_modules/bootstrap/js/modal.js');

new LazyLoad({ // eslint-disable-line no-new
    threshold: 500,
    elements_selector: 'img'
});

$(function() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    /*
    ======================================================================
     Send some initial dataLayer values
    ======================================================================
    */
    dataLayer.push({
        bezoeker: {
            status: window.authStatus
        }
    });

    /*
    ======================================================================
     Initialize
    ======================================================================
    */
    Initializer.init();

    /*
    ======================================================================
     Initialize watchers
    ======================================================================
    */
    Analytics.init();
    ModalWatcher.init();
    MultistepFormWatcher.init();
    ValidationWatcher.init();
    PostalCodeLookupWatcher.init();
    MatchHeightWatcher.init();
    SocialWatcher.init();
    LikeVacancyWatcher.init();
    LikeCandidateWatcher.init();
    FormAjaxSubmitWatcher.init();
    AjaxUpload.init();
    FBTriggers.init();

    /*
    ======================================================================
     Initialize modules & components
    ======================================================================
    */
    const StudentSentence = new DynamicSentence('student'),
        CompanySentence = new DynamicSentence('company');

    CookieBar.init();
    SmoothResults.init();
    ToggleSidebar.init();
    TestimonialCarousel.init();
    TextRotator.init();
    StickyBar.init();
    Prefiller.init();
    Forms.init();
    SubNavigation.init();
}());
