'use strict';

import $ from 'jquery';
import Store from '../components/storage';

/* globals dataLayer */
export default {
    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('click', '[data-multistep-form-step]', this.onClickChangeStep.bind(this));
        $('body').on('click', '[data-multistep-submit]', this.goToFirstInvalidStep.bind(this));
        $('body').on('submit', '[data-multistep-form]', this.onSubmit.bind(this));
        $('body').on('change-step', '[data-multistep-form]', this.onChangeStep.bind(this));
    },

    onSubmit(e) {
        const $form = $(e.currentTarget),
            formAction = $form.attr('action');

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'multistep-form',
            eventAction: 'submit',
            eventLabel: formAction
        });
        // Add on submit logic
        return true;
    },

    goToFirstInvalidStep() {
        $('[data-multistep-form]').each((index, form) => {
            const $form = $(form),
                firstInvalidStep = this.getFirstInvalidStep($form);

            console.log(firstInvalidStep);
            if (firstInvalidStep) {
                $form.trigger('change-step', [firstInvalidStep, false]);
            }
        });
    },

    getFirstInvalidStep($form) {
        let $formSteps = $form.find('[data-form-step-state]'),
            firstInvalidStep = false;

        if (!$form || $form.length === 0) {
            return;
        }

        $formSteps = $form.find('[data-form-step-state]');
        $formSteps.each((index, step) => {
            const $step = $(step),
                stepName = $step.data('form-step');

            if (this.hasValidationErrors($step)) {
                firstInvalidStep = stepName;

                return false;
            }
        });

        return firstInvalidStep;
    },

    onClickChangeStep(e) {
        const $submit = $(e.currentTarget),
            stepName = $submit.data('multistep-form-step'),
            $form = $submit.closest('[data-multistep-form]'),
            shouldValidate = !!$submit.data('multistep-form-step-previous');

        e.preventDefault();

        $form.trigger('change-step', [stepName, shouldValidate]);
    },

    onChangeStep(e, stepName, shouldValidate) {
        const $form = $(e.currentTarget),
            $currentStep = $form.find('[data-form-step-state="current"]'),
            currentStepName = $currentStep.data('form-step');

        if (!stepName) {
            return;
        }

        const $step = $form.find(`[data-form-step="${stepName}"]`);

        // Check if all fields in the group are valid using a promise.
        // Assumed all fields in a form step are in the same group.
        if (!shouldValidate) {
            $form.parsley().whenValidate({ group: currentStepName })
                .done(function() {
                    if ($form.attr('id')) {
                        Store.set('FORM-' + $form.attr('id'), $form.serialize());
                    }
                    $currentStep.attr('data-form-step-state', 'completed');
                    $step.attr('data-form-step-state', 'current');

                    dataLayer.push({
                        event: 'gaEvent',
                        eventCategory: 'multistep-form',
                        eventAction: 'change-step',
                        eventLabel: stepName
                    });
                }).catch(function() {
                    dataLayer.push({
                        event: 'gaEvent',
                        eventCategory: 'multistep-form',
                        eventAction: 'has-errors',
                        eventLabel: $step.data('form-step')
                    });
                });
        } else {
            $currentStep.attr('data-form-step-state', 'completed');
            $step.attr('data-form-step-state', 'current');

            dataLayer.push({
                event: 'gaEvent',
                eventCategory: 'multistep-form',
                eventAction: 'change-step',
                eventLabel: stepName
            });
        }
    },

    hasValidationErrors($step) {
        let hasErrors = false;

        if (!$step || $step.length === 0) {
            return;
        }

        const $stepFields = $step.find('[data-parsley-group]');

        if (window.Parsley && $stepFields.length > 0) {
            $stepFields.each(function(index, field) {
                if ($(field).attr('data-parsley-remote') === undefined) {
                    $(field).parsley().validate();

                    if (!$(field).parsley().isValid()) {
                        hasErrors = true;
                    }
                }
            });
        }

        dataLayer.push({
            event: 'gaEvent',
            eventCategory: 'multistep-form',
            eventAction: 'has-errors',
            eventLabel: $step.data('form-step')
        });

        return hasErrors;
    }
};
