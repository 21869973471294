import Initializer from './initializer';

export default {
    init() {
        this.bindEvents();
        this.checkDuplicateLimits();
    },

    bindEvents() {
        $('body').on('click', '[data-repeat-form-section]', this.onRepeatSection.bind(this));
        $('body').on('click', '[data-remove-form-section]', this.onRemoveSection.bind(this));
        $('body').on('submit', 'form', this.onGlobalFormSubmit.bind(this));
    },

    onGlobalFormSubmit(e) {
        const $form = $(e.currentTarget),
            $loadSubmits = $form.find('[data-button-loading]');

        $loadSubmits.attr('disabled', true);
        $loadSubmits.attr('data-button-loading', 'loading');

        return true;
    },

    onRepeatSection(e) {
        const $repeatTrigger = $(e.currentTarget),
            $repeatableSections = $(`[data-form-section="${$repeatTrigger.data('repeat-form-section')}"]`),
            $repeatableSection = $repeatableSections.last();

        // Unbind select2 & date pickers, as they don't clone very well.
        Initializer.destroySelect2();
        Initializer.destroyDatepickers();

        const $clonedSection = $repeatableSection.clone();

        if ($clonedSection.length === 0) {
            return false;
        }

        // Hide the cloned section, so we can first manipulate it before showing it to the user.
        $clonedSection.hide();

        // Make sure indices are increased. For saving purposes.
        this.increaseIndices($clonedSection);

        // Clear the input fields
        this.clearFields($clonedSection);

        // Add it to the page (still hidden).
        $repeatableSection.after($clonedSection);

        // Set removable to true. Now the remove button will display (css property).
        $clonedSection.attr('data-form-section-removable', true);

        // Reinitialize select2 & pickadate.
        Initializer.initSelect2();
        Initializer.initDatepickers();

        // Show the cloned section.
        $clonedSection.slideDown();

        this.checkDuplicateLimits();
    },

    onRemoveSection(e) {
        const $removeTrigger = $(e.currentTarget),
            $removableSection = $removeTrigger.closest('[data-form-section]'),
            confirmed = confirm('Weet je zeker dat je dit veld wilt verwijderen?');

        if ($removableSection.length === 0 || !confirmed) {
            return;
        }

        $removableSection.slideUp('normal', function() {
            $removableSection.remove();
            this.checkDuplicateLimits();
        }.bind(this));
    },

    checkDuplicateLimits() {
        const $limitedSections = $('[data-max-duplicates]');

        $limitedSections.each(function(index, section) {
            const $section = $(section),
                sectionName = $section.attr('data-form-section'),
                $sectionTriggers = $(`[data-repeat-form-section="${sectionName}"]`),
                maxDupes = $section.attr('data-max-duplicates'),
                sectionCount = $(`[data-form-section="${sectionName}"]`).length;

            if (sectionCount >= maxDupes) {
                $sectionTriggers.attr('disabled', 'disabled');
            } else {
                $sectionTriggers.removeAttr('disabled');
            }
        });
    },

    increaseIndices($element) {
        const $names = $element.find('[name]');

        $names.each((index, element) => {
            const $el = $(element),
                name = $el.attr('name'),
                indexMatch = name.match(/\[[0-9]+\]/g);

            if (!name || !indexMatch) {
                return false;
            }

            const currentIndex = indexMatch[0].replace('[', '').replace(']', ''),
                newName = name.replace(/\[[0-9]+\]/g, `[${parseInt(currentIndex, 10) + 1}]`);

            $el.attr('name', newName);
            $el.attr('id', newName);

            // Pickadate adds a _submit duplicate hidden input which shouldn't be duplicated. So remove it.
            // Awesome hax.
            if (newName.includes('_submit')) {
                $el.remove();
            }
        });
    },

    clearFields($element) {
        $element.find('input:not([type="checkbox"], [type="radio"]), textarea').val('');
    }

};
