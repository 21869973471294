import Parsley from 'parsleyjs';

export default {
    init() {
        window.ParsleyConfig.errorsWrapper = '<ul class="form__errors"></ul>';
        window.ParsleyConfig.errorTemplate = '<li class="form__errors__message"></li>';

        this.addCustomValidations();
        this.setMessages();
    },

    setMessages() {
        Parsley.addMessages('nl', {
            defaultMessage: 'Deze waarde lijkt onjuist.',
            type: {
                email: 'Wil je een geldig e-mailadres invullen (let even op verborgen spaties)?',
                url: 'Dit lijkt geen geldige URL te zijn.',
                number: 'Deze waarde moet een nummer zijn.',
                integer: 'Deze waarde moet een nummer zijn.',
                digits: 'Deze waarde moet numeriek zijn.',
                alphanum: 'Deze waarde moet alfanumeriek zijn.'
            },
            notblank: 'Deze waarde mag niet leeg zijn.',
            required: 'Wil je dit veld nog invullen?',
            pattern: 'Deze waarde lijkt onjuist te zijn.',
            min: 'Deze waarde mag niet lager zijn dan %s.',
            max: 'Deze waarde mag niet groter zijn dan %s.',
            range: 'Deze waarde moet tussen %s en %s liggen.',
            minlength: 'Je wachtwoord is te kort. Deze moet uit minimaal %s karakters bestaan.',
            maxlength: 'Deze waarde is te lang. Deze mag maximaal %s karakters lang zijn.',
            length: 'Deze waarde moet tussen %s en %s karakters lang zijn.',
            equalto: 'Je wachtwoorden zijn niet identiek, nog een keertje proberen!',
            remote: 'Dit e-mailadres is al in gebruik.'
        });

        Parsley.setLocale('nl');
    },

    addCustomValidations() {
        window.Parsley.addValidator('zipcode', {
            requirementType: 'regexp',
            validateString(value, requirement) {
                const match = value.match(/^[1-9][0-9]{3}\s?[a-zA-Z]{2}$/) || false;

                return match;
            },
            messages: {
                en: 'The postal code you entered doesn\'t seem to conform to the dutch postal code format.',
                nl: 'Vul je wel een correcte Nederlandse postcode in (4 cijfers en 2 letters)?'
            }
        });

        window.Parsley.addValidator('phoneNl', {
            requirementType: 'regexp',
            validateString(value, requirement) {
                const match = value.match(/(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/) || false;

                return match;
            },
            messages: {
                en: 'The phone number you entered doesn\'t seem to conform to the dutch phone number format.',
                nl: 'Vul je wel een correct Nederlands telefoonnummer in (zonder streepjes en met 10 cijfers)?'
            }
        });

        window.Parsley.addAsyncValidator('remote-email', function(xhr) { return xhr.status === 404; });
    }
};
