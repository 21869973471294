'use strict';

import $ from 'jquery';

import Cookies from 'js-cookie';

/*
|--------------------------------------------------------------------------
| CookieBar
|--------------------------------------------------------------------------
|
| Cookie bar to allow users to consent to cookies.
|
*/

export default {
    /**
     * Initialize class
     */
    init() {
        // If there is no consent cookie, show the cookie bar
        if (this.readCookie('DN_cookie_consent') === null && this.readCookie('DN_cookie_dismiss') === null) {
            $('.cookie-bar').addClass('cookie-bar--visible');
        }

        this.bindEvents();
    },

    // Bind all events
    bindEvents() {
        $('.cookie-bar__button--accept').on('click', this.cookieAccepted.bind(this));
        $('.cookie-bar__button--dismiss').on('click', this.cookieDismissed.bind(this));
    },

    // Remove cookie bar and set consent cookie
    cookieAccepted() {
        $('.cookie-bar').removeClass('cookie-bar--visible');

        // Set consent cookie
        Cookies.set('DN_cookie_consent', 1, { expires: 365, path: '/' });
        Cookies.remove('DN_cookie_dismiss');

        const dataLayer = window.dataLayer || [];
        dataLayer.push({ event: 'cookie-consent' });
        window.location.reload();
    },

    // Remove cookie bar and set dismiss cookie
    cookieDismissed() {
        $('.cookie-bar').removeClass('cookie-bar--visible');

        // Set dismiss cookie
        Cookies.set('DN_cookie_dismiss', 1, { expires: 30, path: '/' });
        Cookies.remove('DN_cookie_consent');

        const dataLayer = window.dataLayer || [];
        dataLayer.push({ event: 'cookie-noconsent' });
        window.location.reload();
    },

    expired() {
        return '; expires=' + new Date(0).toUTCString();
    },

    expires(days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        return '; expires=' + date.toUTCString();
    },

    readCookie(name) {
        const nameEQ = name + '=',
            ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    }
};
