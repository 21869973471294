export default {
    init() {
        this.bindEvents();
        this.matchHeights();
    },

    bindEvents() {
        // Listen for the match-heigh-refresh event, so other modules are able to refresh the offsets.
        $('body').on('match-height-refresh', this.matchHeights);
    },

    matchHeights() {
        // Fetch all elements which need their heights to be matched.
        $('body').find('[data-match-height]').each(function() {
            const $this = $(this),
                matchTarget = $this.data('match-height'),
                matchParent = $this.data('match-parent'),
                $parent = matchParent ? $this.closest(matchParent) : null;

            let matchedHeight = null;

            // Get the height of the target which the element should be matched to.
            if (matchTarget && $parent && $parent.length > 0) {
                const $match = $parent.find(matchTarget);
                matchedHeight = $match.innerHeight();
            }

            // Make the element the same height as the target.
            if (matchedHeight) {
                $this.height(matchedHeight);
            }
        });
    }
};
