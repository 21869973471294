'use strict';

export default {
    init() {
        this.bindEvents();
    },

    bindEvents() {
        $('body').on('submit', '[data-form-ajax]', this.onSubmitForm.bind(this));
    },

    onSubmitForm(e) {
        e.preventDefault();
        const $form = $(e.currentTarget);

        if (!$form.is(':disabled')) {
            this.submitForm($form);
        }
    },

    submitForm($form) {
        // Post form to the original action, which is assumed to be an ajax endpoint
        const jqxhr = $.post($form.attr('action'), $form.serialize());

        jqxhr.done(this.onLikeSuccess.bind($form));
        jqxhr.fail(this.onLikeFail.bind($form));
    },

    onLikeSuccess(data) {
        const $modal = this.parents('.modal');
        if ($modal.length) {
            $modal.removeClass('modal--horizontal');
            $modal.addClass('modal--vertical');

            $modal.find('[data-ajax-result="before"]').addClass('hidden');
            $modal.find('[data-ajax-result="after"]').removeClass('hidden');
        }
    },

    onLikeFail(data) {
        console.error(`Something went wrong liking candidate with id: ${this.currentID}`);
    }
};
