import $ from 'jquery';

export default {
    init() {
        this.$listItems = $('[data-trigger-subnav]');
        this.$links = this.$listItems.find('> a');
        this.bindEvents();
    },

    bindEvents() {
        this.$links.on('touchstart', this.toggleSubnav.bind(this));
    },

    toggleSubnav(e) {
        e.preventDefault();
        const $target = $(e.target),
            $li = $target.parents('li');
        // Check if the item is visible - if it isn't, the mobile click-navigation is already used.
        if ($target.is(':visible')) {
            this.$listItems.removeClass('active');
            $li.addClass('active');
            $li.find('.navigation__subnav').toggle();
        }
    }
};
